.fixedsticky {
	position: -webkit-sticky;
	position: -moz-sticky;
	position: -ms-sticky;
	position: -o-sticky;
	position: sticky;
}
/* When position: sticky is supported but native behavior is ignored */
.fixedsticky-withoutfixedfixed .fixedsticky-off,
.fixed-supported .fixedsticky-off {
	position: static;
}
.fixedsticky-withoutfixedfixed .fixedsticky-on,
.fixed-supported .fixedsticky-on {
	position: fixed;
}
.fixedsticky-dummy {
	display: none;
}
.fixedsticky-on + .fixedsticky-dummy {
	display: block;
}